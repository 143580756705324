import { useLazyGetAwardeeByIdQuery } from "../../services/rtkQueryServices/checkApiService";
import Awardee from "../Awardee/Awardees";
import Slider from "../Shared/Widgets/Slider/Slider";
import React, { useState, useEffect } from "react";
import "./Awardees.scss";

const NetworkGraphAwardees = ({ awardeesData, currentData }) => {
  const [sliderTitle, setSliderTitle] = useState("");
  const [setAwardeeData, { data: awardeeData }] = useLazyGetAwardeeByIdQuery();
  const [openSlider, setOpenSlider] = useState(false);
  useEffect(() => {
    if (openSlider) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [openSlider]);

  const handleAwardeeclicked = (awardee) => {
    awardee.RaiseProjectID && setAwardeeData(awardee?.RaiseProjectID);
    setSliderTitle(awardee?.FullName);
    setOpenSlider(true);
  };

  const handleClose = () => {
    setOpenSlider(false);
    document.body.style.overflow = "auto";
  };
  return (
    <>
      <div className="font-weight-bold mb-2 awardees-header-title-wrapper">
        List of Awardees common between{" "}
        {currentData?.map((data, index) => (
          <span>
            {data?.label}
            {currentData.length !== index + 1 && ", "}
          </span>
        ))}
      </div>

      {awardeesData.map((awardee) => (
        <div
          onClick={() => {
            handleAwardeeclicked(awardee);
          }}
          className="awardee-full-name-wrapper"
        >
          {awardee.FullName}
        </div>
      ))}
      <Slider isOpen={openSlider} onClose={handleClose}>
        <Awardee
          awardeeData={awardeeData}
          handleOnCloseClick={handleClose}
          sliderTitle={sliderTitle}
        />
      </Slider>
    </>
  );
};

export default NetworkGraphAwardees;
