import Counts from "../Counts/counts";
import React, { useState, useEffect } from "react";
import RecentAwardees from "../RecentAwardees/recentAwardees";
import Test from "../Awards/awards";
import "./home.scss";
import Awards from "../Awards/awards";
import UTAFacultyAwards from "../UTAFacultyAwards/UTAFacultyAwards";
import AwardsFilters from "../AwardsFilters/AwardsFilters";

const HomeComponent = () => {
  const [localFilter, setLocalFilter] = useState({
    awardName: "",
    organization: "",
    firstName: "",
    lastName: "",
  });

  const [filterCriteria, setFilterCriteria] = useState({
    pageNumber: 1,
    pageSize: 20,
  });

  const clearFilter = () => {
    setLocalFilter({
      awardName: "",
      organization: "",
      firstName: "",
      lastName: "",
    });
    setFilterCriteria({
      pageNumber: 1,
      pageSize: 20,
    });
  };

  const applyFilter = (localFilterValues) => {
    setFilterCriteria((prev) => ({
      ...prev,
      pageNumber: 1,
      awardName: localFilterValues.awardName,
      organization: localFilterValues.organization,
      firstName: localFilterValues.firstName,
      lastName: localFilterValues.lastName,
    }));
  };
  return (
    <>
      <Counts />
      <div className="bg-white my-4 p-3 awards-filter-wrapper">
        <AwardsFilters
          setFilterCriteria={setLocalFilter}
          applyFilter={applyFilter}
          clearFilter={clearFilter}
          isAwardsPage={true}
          isHomePage={true}
        />
      </div>
      {(filterCriteria?.lastName === "" ||
        filterCriteria?.lastName === undefined) &&
        filterCriteria?.awardName && (
          <Awards filterCriteria={filterCriteria} isHomePage={true} />
        )}
      {(filterCriteria?.lastName === "" ||
        filterCriteria?.lastName === undefined) &&
        (filterCriteria?.awardName === "" ||
          filterCriteria?.awardName === undefined) && <UTAFacultyAwards />}
      {filterCriteria?.lastName && (
        <RecentAwardees filterCriteria={filterCriteria} isHomepage={true} />
      )}
    </>
  );
};
export default HomeComponent;
