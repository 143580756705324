import "./Grid.scss";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as LeftCoolicon } from "../../../assets/svg/coolicon_left.svg";
import { ReactComponent as RightCoolicon } from "../../../assets/svg/coolicon_right.svg";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ReactComponent as NoSearchData } from "../../../assets/svg/no_search_data.svg";
import { ReactComponent as NoData } from "../../../assets/svg/no_data.svg";
import { ReactComponent as Sort } from "../../../assets/svg/Sort.svg";
import { TextField } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
// import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";

// interface DefaultColumnDefs {
//   style?: any;
//   sortable?: boolean;
//   align?: "left" | "center" | "right" | "inherit" | "justify";
// }

// interface GridProps {
//   columnDefs?: GridColumnDefs[];
//   headerStyle?: any;
//   rowData?: any[];
//   serverSidePagination?: boolean;
//   serverSideSorting?: boolean;
//   masterDetail?: boolean;
//   detailCellRendererParams?: any;
//   defaultColDef?: DefaultColumnDefs;
//   withoutHeader?: boolean;
//   pageSizeList?: number[];
//   pageSize?: number;
//   page?: number;
//   canSearch?: boolean;
//   searchInputValue?: string;
//   searchFieldName?: string;
//   actionButtons?: ReactNode;
//   searchResults?: ReactNode;
//   headerContent?: string;
//   showFilterIcon?: boolean;
//   showPagination?: boolean;
//   showTotalRowCount?: boolean;
//   filterTemplate?: ReactNode;
//   rowCount?: number;
//   SelectOnRowClick?: boolean;
//   isLoading?: boolean;
//   enableDefaultSearch?: boolean;
//   searchFields?: any[];
//   gridType?: string;
//   resetSort?: Boolean;
//   isSearch?: Boolean | false;
//   uniqueIdentifierFieldName: string;
//   showSearchFilterDropdown?: boolean;
//   searchFilterDropdownMenuList?: string[];
//   searchFilterDropdownPlaceholder?: string;
//   searchString?: string;
//   searchFilterDropdownOnChange?: (val: string[] | number[]) => void;
//   sortBy?: (columnName: string, sortOrder: string) => void;
//   onPageChange?: (value: number) => void;
//   onPageSizeChange?: (value: number) => void;
//   setSearchQuery?: (value: string) => void;
//   onRowClicked?: (row: any) => void;
//   onRowDoubleClicked?: (row: any) => void;
//   onRowMouseOut?: (row: any) => void;
//   onRowMouseEnter?: (row: any) => void;
//   onRowMouseDown?: (row: any) => void;
//   onRowMouseUp?: (row: any) => void;
//   onCellClicked?: (rowCell: any) => void;
//   onCellDoubleClicked?: (rowCell: any) => void;
//   onCellMouseOut?: (rowCell: any) => void;
//   onCellMouseEnter?: (rowCell: any) => void;
//   onCellMouseDown?: (rowCell: any) => void;
//   onCellMouseUp?: (rowCell: any) => void;
//   onRowSelected?: (row: any) => void;
//   onSelectionChanged?: (rows: any[]) => void;
//   onRowGroupOpened?: (row: any) => void;
//   onRowGroupClosed?: (row: any) => void;
//   disableRow?: (row: any) => boolean;
// }

function Grid(props) {
  let rowData = useMemo(() => props?.rowData || [], [props]);
  let rowCount = useMemo(
    () => props?.rowCount || props?.rowData?.length || 0,
    [props]
  );
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(props.pageSize || 20);
  const [localRowData, setLocalRowData] = useState([]);
  const [inputValue, setInputValue] = useState(props?.searchString || "");
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    setLocalRowData(rowData);
  }, [rowData]);

  useEffect(() => {
    props?.onSelectionChanged && props?.onSelectionChanged(selected);
  }, [selected]);

  useEffect(() => {
    props?.pageSize && setPageSize(props?.pageSize || 20);
  }, [props?.pageSize]);
  useEffect(() => {
    props.page && setPage(props?.page);
  }, [props.page]);
  useEffect(() => {
    if (props?.rowData) sortRowData(sort?.sortBy, sort?.sortOrderArray[0]);
  }, [sort]);

  useEffect(() => {
    if (props.onPageChange) props?.onPageChange(page);
  }, [page]);

  useEffect(() => {
    if (props.onPageSizeChange) props?.onPageSizeChange(pageSize);
  }, [pageSize]);

  useEffect(() => {
    props.searchInputValue && setInputValue(props.searchInputValue);
  }, [props.searchInputValue]);

  useEffect(() => {
    if (props?.resetSort) {
      resetSortOder();
    }
  }, [rowData, props?.resetSort]);

  function selectedAll() {
    if (searchData.length <= 0) {
      let tempRowData = localRowData ? [...localRowData] : [];
      if (localRowData?.length === selected?.length)
        tempRowData = localRowData?.map((row) => {
          return { ...row, isSelected: false };
        });
      else
        tempRowData = localRowData?.map((row) => {
          return { ...row, isSelected: true };
        });
      setSelected(tempRowData?.filter((row) => row?.isSelected === true));
      setLocalRowData(tempRowData);
    } else {
      let tempSearchData = [...searchData];
      let selectedSearchData = searchData.filter(
        (obj) => obj["isSelected"] === true
      );

      if (selectedSearchData.length === searchData.length) {
        tempSearchData = searchData?.map((row) => {
          var index = localRowData?.findIndex(
            (obj) =>
              obj[props.uniqueIdentifierFieldName] ===
              row[props.uniqueIdentifierFieldName]
          );
          localRowData?.splice(index, 1, {
            ...localRowData[index],
            isSelected: false,
          });
          return { ...row, isSelected: false };
        });
      } else {
        tempSearchData = searchData.map((row) => {
          var index = localRowData.findIndex(
            (obj) =>
              obj[props.uniqueIdentifierFieldName] ===
              row[props.uniqueIdentifierFieldName]
          );
          localRowData?.splice(index, 1, {
            ...localRowData[index],
            isSelected: true,
          });
          return { ...row, isSelected: true };
        });
      }
      setSelected(localRowData?.filter((row) => row?.isSelected === true));
      setLocalRowData([...localRowData]);
      setSearchData(tempSearchData);
    }
  }

  function sortRowData(columnName, sortOrder) {
    props?.sortBy && props?.sortBy(columnName, sortOrder);
    setSortOrder(sortOrder);
    if (!props?.serverSideSorting) {
      let tempRowData = [...rowData];
      var collator = new Intl.Collator([], { numeric: true });
      tempRowData?.sort((a, b) =>
        collator.compare(a[columnName], b[columnName])
      );
      if (sortOrder === "desc") setLocalRowData([...tempRowData].reverse());
      else if (sortOrder === "asc") setLocalRowData([...tempRowData]);
      else if (sortOrder === "unsorted") setLocalRowData([...rowData]);
    }
  }

  function handleSortOrder(columnName) {
    if (sort?.sortBy === columnName && !props?.resetSort) {
      let sortOrder = sort?.sortOrderArray;
      sortOrder.push(sortOrder?.shift());
      setSort({ sortBy: columnName, sortOrderArray: [...sortOrder] });
    } else {
      setSort({
        sortBy: columnName,
        sortOrderArray: ["asc", "desc", "unsorted"],
      });
    }
  }

  function resetSortOder() {
    setSortOrder("unsorted");
  }

  function toggleProperty(index, property, uniqueIdentifierValue) {
    var elementIndex = localRowData?.findIndex(
      (obj) => obj[props.uniqueIdentifierFieldName] === uniqueIdentifierValue
    );
    if (localRowData[elementIndex]?.[property]) {
      localRowData?.splice(elementIndex, 1, {
        ...localRowData[elementIndex],
        [property]: false,
      });
    } else {
      localRowData?.splice(elementIndex, 1, {
        ...localRowData[elementIndex],
        [property]: true,
      });
    }
    setSelected(localRowData?.filter((row) => row?.isSelected === true));
    setLocalRowData([...localRowData]);
    if (searchData.length > 0) {
      if (searchData[index]?.[property]) {
        searchData?.splice(index, 1, {
          ...searchData[index],
          [property]: false,
        });
      } else {
        searchData?.splice(index, 1, {
          ...searchData[index],
          [property]: true,
        });
      }
      setSearchData([...searchData]);
      return searchData[index];
    }
    return localRowData[elementIndex];
  }

  return (
    <>
      <div className="mui-grid-wrapper" style={{ overflowX: "auto" }}>
        {props?.headerContent && (
          <div className="d-flex align-items-center justify-content-between">
            <header className="flex-grow-1">{props?.headerContent}</header>
            {/* <div className="flex-grow-1 mx-4">
              <TextField
                value={props?.searchValue}
                onChange={(e) => props?.setSearchValue(e.target.value)}
                variant="outlined"
                placeholder={`Search ` + (props?.searchFieldName || "")}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <SearchIcon position="start">
                      <AccountCircle />
                    </SearchIcon>
                  ),
                }}
                fullWidth
              />
            </div> */}
          </div>
        )}
        <Table
          className="grid-container"
          stickyHeader
          aria-label="sticky table"
        >
          {!props?.withoutHeader && (
            <TableHead
              className={
                "grid-header " +
                (props.gridType === "audit" ? "audit-header-wrapper" : "")
              }
            >
              <TableRow>
                {props?.masterDetail && <TableCell className="grid-cell" />}
                {props.columnDefs?.map((column) => (
                  <>
                    {column?.headerCheckboxSelection && (
                      <TableCell
                        className="grid-cell-checkbox"
                        padding="checkbox"
                      >
                        <Checkbox
                          color="primary"
                          className="grid-checkbox"
                          onClick={selectedAll}
                          checked={selected?.length === rowData?.length}
                          indeterminate={
                            selected?.length > 0 &&
                            selected?.length < rowData?.length
                          }
                        />
                      </TableCell>
                    )}
                    {!column?.hide && (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ ...column.style }}
                        className="grid-cell"
                      >
                        <div className={"grid-header-cell"}>
                          <span
                            style={{
                              display: "inline",
                              ...props?.defaultColDef,
                              ...column.style,
                            }}
                            title={column?.headerName}
                          >
                            {column?.headerName
                              ? column?.valueFormatter
                                ? column.valueFormatter(column?.headerName)
                                : column?.headerName
                              : column?.field}
                            {column?.headerCustomComponent &&
                              column?.headerCustomComponent}
                            {(column?.sortable ||
                              (props?.defaultColDef?.sortable &&
                                column?.sortable !== false)) && (
                              <span
                                className="sort-icon "
                                onClick={() => {
                                  handleSortOrder(column?.field);
                                }}
                              >
                                {(sortOrder === "asc" ||
                                  sortOrder === "desc") &&
                                sort?.sortBy === column?.field ? (
                                  <svg
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    width="14px"
                                    height="14px"
                                    className={sortOrder}
                                  >
                                    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                                  </svg>
                                ) : (
                                  <Sort />
                                )}
                              </span>
                            )}
                          </span>
                        </div>
                      </TableCell>
                    )}
                  </>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody className="grid-body">
            {props?.columnDefs &&
              localRowData &&
              !props?.isLoading &&
              localRowData?.length > 0 &&
              localRowData?.map((row, index) => (
                <>
                  <TableRow
                    key={index}
                    className={
                      "grid-row " +
                      (row?.isSelected && " checked") +
                      (row.isActive && " disable")
                    }
                    onClick={() => {
                      if (props?.SelectOnRowClick) {
                        let rowData = toggleProperty(
                          index + (page - 1) * pageSize,
                          "isSelected",
                          row[props.uniqueIdentifierFieldName]
                        );
                        props?.onRowSelected && props?.onRowSelected(rowData);
                      }
                      props?.onRowClicked && props?.onRowClicked(row);
                    }}
                    onMouseOut={() => {
                      props?.onRowMouseOut && props?.onRowMouseOut(row);
                    }}
                    onMouseEnter={(e) => {
                      props?.onRowMouseEnter && props?.onRowMouseEnter(row);
                    }}
                    onMouseDown={(e) => {
                      props?.onRowMouseDown && props?.onRowMouseDown(row);
                    }}
                    onMouseUp={() => {
                      props?.onRowMouseUp && props?.onRowMouseUp(row);
                    }}
                    onDoubleClick={() => {
                      props?.onRowDoubleClicked &&
                        props?.onRowDoubleClicked(row);
                    }}
                  >
                    {props?.masterDetail &&
                      (row[props.detailCellRendererParams?.expandColumnName] ? (
                        <TableCell
                          className="expand-icon-cell "
                          padding="checkbox"
                          onClick={(e) => {
                            row?.isOpened
                              ? props?.onRowGroupClosed &&
                                props?.onRowGroupClosed(row)
                              : props?.onRowGroupOpened &&
                                props?.onRowGroupOpened(row);
                            toggleProperty(
                              index + (page - 1) * pageSize,
                              "isOpened",
                              row[props.uniqueIdentifierFieldName]
                            );
                            e.stopPropagation();
                          }}
                        >
                          <span
                            className={
                              "expand-icon " +
                              (row?.isOpened ? "expanded" : "collapsed")
                            }
                          >
                            {/* <SVG src={Arrow}></SVG> */}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell padding="checkbox"></TableCell>
                      ))}
                    {props.columnDefs?.map((columnDef, index) => {
                      return (
                        <>
                          {columnDef?.checkboxSelection && (
                            <TableCell
                              padding="checkbox"
                              className="grid-cell-checkbox"
                              key={index}
                              sx={{ ...columnDef.style }}
                            >
                              <Checkbox
                                className="grid-checkbox"
                                onClick={() => {
                                  if (!props?.SelectOnRowClick) {
                                    let rowData = toggleProperty(
                                      index + (page - 1) * pageSize,
                                      "isSelected",
                                      row[props.uniqueIdentifierFieldName]
                                    );
                                    props?.onRowSelected &&
                                      props?.onRowSelected(rowData);
                                  }
                                  props?.onRowClicked &&
                                    props?.onRowClicked(row);
                                }}
                                color="primary"
                                checked={row?.isSelected || false}
                              />
                            </TableCell>
                          )}
                          {!columnDef?.hide && (
                            <TableCell
                              onClick={() => {
                                props?.onCellClicked &&
                                  props?.onCellClicked({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              onDoubleClick={() => {
                                props?.onCellDoubleClicked &&
                                  props?.onCellDoubleClicked({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              onMouseOut={() => {
                                props?.onCellMouseOut &&
                                  props?.onCellMouseOut({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              onMouseEnter={(e) => {
                                props?.onCellMouseEnter &&
                                  props?.onCellMouseEnter({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              onMouseDown={(e) => {
                                props?.onCellMouseDown &&
                                  props?.onCellMouseDown({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              onMouseUp={() => {
                                props?.onCellMouseUp &&
                                  props?.onCellMouseUp({
                                    [columnDef?.field]: row[columnDef?.field],
                                  });
                              }}
                              className={`grid-cell ${
                                props?.disableRow &&
                                props?.disableRow(row) &&
                                !columnDef?.alwaysEnabled &&
                                "grid-disabled-row"
                              }`}
                              sx={{ ...columnDef.style }}
                              align={
                                columnDef?.align || props?.defaultColDef?.align
                              }
                              title={
                                row[columnDef?.field]
                                  ? columnDef?.cellRenderer
                                    ? ""
                                    : row[columnDef?.field]
                                  : ""
                              }
                            >
                              {row[columnDef?.field]
                                ? columnDef?.cellRenderer
                                  ? columnDef?.cellRenderer({
                                      value: row[columnDef?.field],
                                      data: row,
                                    })
                                  : row[columnDef?.field]
                                : columnDef?.cellRenderer &&
                                  columnDef?.cellRenderer({
                                    value: row[columnDef?.field],
                                    data: row,
                                  })}
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>

                  {row?.isOpened && (
                    <TableRow>
                      {props?.masterDetail && (
                        <>
                          <TableCell></TableCell>
                          {/* should be added based on the number of checkboxes*/}
                          <TableCell></TableCell>
                          {/*  */}
                        </>
                      )}
                      <TableCell
                        className="grid-cell"
                        colSpan={props?.columnDefs?.length}
                      >
                        <Collapse
                          in={row?.isOpened}
                          timeout="auto"
                          unmountOnExit
                        >
                          {row[
                            props.detailCellRendererParams?.expandColumnName
                          ] && (
                            <Grid
                              isSearch={props.isSearch}
                              columnDefs={
                                props.detailCellRendererParams?.columnDefs
                              }
                              rowData={
                                row[
                                  props.detailCellRendererParams
                                    ?.expandColumnName
                                ]
                              }
                              withoutHeader={
                                props.detailCellRendererParams?.withoutHeader
                              }
                              masterDetail={
                                props.detailCellRendererParams?.masterDetail
                              }
                              detailCellRendererParams={
                                props.detailCellRendererParams
                                  ?.detailCellRendererParams
                              }
                              uniqueIdentifierFieldName={
                                props.uniqueIdentifierFieldName
                              }
                              onRowClicked={props?.onRowClicked}
                              onRowDoubleClicked={props?.onRowDoubleClicked}
                              onRowMouseOut={props?.onRowMouseOut}
                              onRowMouseEnter={props?.onRowMouseEnter}
                              onRowMouseDown={props?.onRowMouseDown}
                              onRowMouseUp={props?.onRowMouseUp}
                              onCellClicked={props?.onCellClicked}
                              onCellDoubleClicked={props?.onCellDoubleClicked}
                              onCellMouseOut={props?.onCellMouseOut}
                              onCellMouseEnter={props?.onCellMouseEnter}
                              onCellMouseDown={props?.onRowMouseDown}
                              onCellMouseUp={props?.onCellMouseUp}
                              onRowSelected={props?.onRowSelected}
                              onRowGroupOpened={props?.onRowGroupOpened}
                              onRowGroupClosed={props?.onRowGroupClosed}
                            ></Grid>
                          )}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
        {(localRowData?.length === 0 || props?.isLoading) && (
          <div className="grid-no-rows-display">
            {props.isLoading ? (
              <span>Loading...</span>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center">
                {props.isSearch ? (
                  <>
                    <NoSearchData className="mb-5" />
                    <span className="mt-3 empty-grid">
                      No search data found
                    </span>
                  </>
                ) : (
                  <>
                    <NoData className="mb-5" />
                    <span className="mt-3 empty-grid">No data to display</span>
                  </>
                )}
              </div>
            )}{" "}
          </div>
        )}
      </div>
      {props?.showPagination && props?.pageSize && (
        <div className="MUI-grid-pagination">
          <div className="grid-pagination-page-sizes align-items-center d-flex">
            <label>Page Size</label>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={pageSize?.toString()}
                onChange={(event) => {
                  setPageSize(parseInt(event.target.value));
                  setPage(1);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {props?.pageSizeList?.map((item, index) => (
                  <MenuItem key={index} value={item?.toString()}>
                    {item?.toString()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props?.showTotalRowCount && (
              <span className="grid-row-count">
                <span>Total Records : </span>
                <span className="grid-total-row-count">
                  {searchData.length > 0 && props.enableDefaultSearch
                    ? searchData.length
                    : rowCount}
                </span>
              </span>
            )}
          </div>
          <div className="grid-pagination-section justify-content-end">
            <button
              onClick={() => {
                if (page > 1) setPage(page - 1);
              }}
              className={
                " prev grid-pagination-prev-button " +
                (page === 1 ? "disabled" : "")
              }
            >
              {/* <SVG src={Arrow}></SVG> */}
              <LeftCoolicon />
              <span>Prev</span>
            </button>
            <Pagination
              count={
                searchData.length > 0 && props.enableDefaultSearch
                  ? Math.ceil(searchData.length / pageSize)
                  : Math.ceil(rowCount / pageSize) || 0
              }
              onChange={(event, page) => {
                setPage(page);
              }}
              size="medium"
              className="grid-pagination-number"
              page={page}
            />
            <button
              onClick={() => {
                if (
                  page <
                  (searchData.length > 0 && props.enableDefaultSearch
                    ? Math.ceil(searchData.length / pageSize)
                    : Math.ceil(rowCount / pageSize))
                )
                  setPage(page + 1);
              }}
              className={
                "next grid-pagination-next-button " +
                (page >=
                (searchData.length > 0 && props.enableDefaultSearch
                  ? Math.ceil(searchData.length / pageSize)
                  : Math.ceil(rowCount / pageSize))
                  ? "disabled"
                  : "")
              }
            >
              <span>Next</span>
              <RightCoolicon />
              {/* <SVG src={Arrow}></SVG> */}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Grid;
