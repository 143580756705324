import { dataStore } from "../constants/dataStore";
import { jwtDecode } from "jwt-decode";
import { routePaths } from "../constants/routePaths";

export class UserService {
  static get isLoggedIn() {
    return localStorage.getItem(dataStore.access_token) ? true : false;
  }

  static get accessToken() {
    return localStorage.getItem(dataStore.access_token);
  }

  static set accessToken(token) {
    if (token) {
      localStorage.setItem(dataStore.access_token, token);
    }
  }

  static get refreshToken() {
    return localStorage.getItem(dataStore.refresh_token);
  }

  static set refreshToken(token) {
    if (token) {
      localStorage.setItem(dataStore.refresh_token, token);
    }
  }

  static get getClaims() {
    let token = localStorage.getItem(dataStore.access_token);
    return token ? jwtDecode(token) : null;
  }

  static get loginCode() {
    return localStorage.getItem(dataStore.login_code);
  }

  static set loginCode(token) {
    if (token) {
      localStorage.setItem(dataStore.login_code, token);
    }
  }

  static get userActiveTab() {
    return sessionStorage.getItem(dataStore.active_tab);
  }

  static set userActiveTab(activeTabId) {
    if (activeTabId) {
      sessionStorage.setItem(dataStore.active_tab, activeTabId);
    }
  }

  static logOut(navigateToLogin = true) {
    localStorage.removeItem(dataStore.access_token);
    localStorage.removeItem(dataStore.refresh_token);
    localStorage.removeItem(dataStore.login_code);
    sessionStorage.removeItem(dataStore.active_tab);
    if (navigateToLogin) {
      window.location.replace(routePaths.login);
    }
  }

  static redirectToSessionExpiry() {
    this.logOut(false);
    window.location.replace(routePaths.sessionExpiry);
  }

  static get isUserTokenExpired() {
    if (this.accessToken) {
      return new Date(jwtDecode(this.accessToken).exp * 1000) < new Date();
    }
    return true;
  }

  static logIn(tokenResponse) {
    this.accessToken = tokenResponse?.token;
    this.refreshToken = tokenResponse?.refreshToken;
  }
}
