import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";

export const checkApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSocietyById: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_SOCIETY_BY_ID}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getAllcounts: builder.query({
      query: () => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_COUNTS}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getRecentAwardees: builder.query({
      query: (filterCriteria) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_RECENT_AWARDEES}`,
          method: "POST",
          body: JSON.stringify(filterCriteria),
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwards: builder.query({
      query: (filterCriteria) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_AWARDS}`,
          method: "POST",
          body: JSON.stringify(filterCriteria),
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
      },
    }),
    getNetworkGraphCounts: builder.query({
      query: (awardIds) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_NETWORK_GRAPH_COUNTS}`,
          method: "POST",
          body: JSON.stringify(awardIds),
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
      },
    }),
    getNetworkGraphAwardees: builder.query({
      query: (awardIds) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_NETWORK_GRAPH_AWARDEES}`,
          method: "POST",
          body: JSON.stringify(awardIds),
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwardeeById: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AWARDEE_BY_ID}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getUTAFacultyById: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_UTA_FACULTY_BY_ID}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwardsBySocietyNumber: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AWARDS_BY_SOCIETY_NUMBER}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwardsBySearchString: builder.query({
      query: (searchString) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AWARDS_BY_SEARCH_STRING}/${searchString}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getSocitiesBySearchString: builder.query({
      query: (searchString) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_SOCIETIES_BY_SEARCH_STRING}/${searchString}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwardeeByIdName: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AWARDEE_BY_ID_NAME}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getAwardeGraphById: builder.query({
      query: (id) => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AWARD_GRAPH_ID}/${id}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
    getUtaFacultyMasterData: builder.query({
      query: () => {
        return {
          url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_UTA_FACULTY_MASTER_DATA}`,
          method: "GET",
          headers: {
            "Content-Type": "*/*",
            Accept: "*/*",
          },
        };
      },
    }),
  }),
});
export const {
  useLazyGetAwardsQuery,
  useGetRecentAwardeesQuery,
  useLazyGetRecentAwardeesQuery,
  useGetAllcountsQuery,
  useLazyGetAwardeeByIdQuery,
  useLazyGetAwardeeByIdNameQuery,
  useGetUtaFacultyMasterDataQuery,
  useLazyGetUTAFacultyByIdQuery,
  useLazyGetAwardsBySearchStringQuery,
  useLazyGetNetworkGraphCountsQuery,
  useLazyGetNetworkGraphAwardeesQuery,
  useLazyGetSocietyByIdQuery,
  useLazyGetSocitiesBySearchStringQuery,
  useLazyGetAwardsBySocietyNumberQuery,
  useLazyGetAwardeGraphByIdQuery,
} = checkApi;
