import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiAppBar from "@mui/material/AppBar";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

function SideBarComponent() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Mobile View Check
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md")); // Tablet View Check
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div className="d-flex align-items-center w-100 justify-content-between">
            {isSmallScreen && (
              <>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
            <Typography
              variant="h6"
              noWrap
              component="div"
              onClick={() => navigate("/")}
              sx={{ cursor: "pointer" }}
            >
              Recognizing Scholars
            </Typography>
            {!isSmallScreen && (
              <div className="d-flex">
                <div
                  className="cursor-pointer me-3"
                  onClick={() => navigate("/awards")}
                >
                  Awards
                </div>
                <div
                  className="cursor-pointer me-3"
                  onClick={() => navigate("/awardees")}
                >
                  Awardees
                </div>
                <div
                  className="cursor-pointer me-3"
                  onClick={() => navigate("/societies")}
                >
                  Societies
                </div>
                <div
                  className="cursor-pointer me-3"
                  onClick={() => navigate("/network_graph")}
                >
                  Network Graph
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => navigate("/uta_faculty_awards")}
                >
                  UTA Faculty Awards
                </div>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={isSmallScreen ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div>
          <div
            className="drawer-link cursor-pointer"
            onClick={() => {
              navigate("/awards");
              handleDrawerClose();
            }}
          >
            Awards
          </div>
          <div
            className="drawer-link cursor-pointer"
            onClick={() => {
              navigate("/awardees");
              handleDrawerClose();
            }}
          >
            Awardees
          </div>
          <div
            className="drawer-link cursor-pointer"
            onClick={() => {
              navigate("/societies");
              handleDrawerClose();
            }}
          >
            Societies
          </div>
          <div
            className="drawer-link cursor-pointer"
            onClick={() => {
              navigate("/network_graph");
              handleDrawerClose();
            }}
          >
            Network Graph
          </div>
          <div
            className="drawer-link cursor-pointer"
            onClick={() => {
              navigate("/uta_faculty_awards");
              handleDrawerClose();
            }}
          >
            UTA Faculty Awards
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default SideBarComponent;
