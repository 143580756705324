import React, { useState, useEffect } from "react";
import Grid from "../Shared/Grid/Grid";
import AwardsFilters from "../AwardsFilters/AwardsFilters";
import {
  useLazyGetRecentAwardeesQuery,
  useLazyGetAwardeeByIdQuery,
} from "../../services/rtkQueryServices/checkApiService";
import "./recentAwardees.scss";
import Slider from "../Shared/Widgets/Slider/Slider";
import Awardee from "../Awardee/Awardees";
import NAM from "../../assets/images/NAM.png";
import NAE from "../../assets/images/NAE.png";
import AAS from "../../assets/images/AAS.png";
import NAS from "../../assets/images/NAS.jpg";
import NAI from "../../assets/images/NAI.jpeg";

const RecentAwardees = (attributes) => {
  const [rowData, setRowData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [openSlider, setOpenSlider] = useState(false);
  const [setAwardsData, { data: awardsData, isLoading }] =
    useLazyGetRecentAwardeesQuery();
  const [setAwardeeData, { data: awardeeData }] = useLazyGetAwardeeByIdQuery();
  const [rowCount, setRowCount] = useState(0);
  const [sliderTitle, setSliderTitle] = useState("");
  const [localFilter, setLocalFilter] = useState({
    awardName: "",
    organization: "",
    firstName: "",
    lastName: "",
  });
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const sortOrder = (columnName, order) => {
    if (order === "asc" || order === "desc") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    }
  };

  useEffect(() => {
    if (awardsData?.data) {
      const formattedData = awardsData.data.map((awardee) => ({
        fullName: awardee.FullName,
        nameFirst: awardee.NameFirst,
        nameLast: awardee.NameLast,
        isAAS: awardee.AAS,
        isNAI: awardee.NAI,
        isNAM: awardee.NAM,
        isNAS: awardee.NAS,
        isUTA: awardee.uta,
        id: awardee.RaiseProjectID,
        noOfAwards: awardee.noOfAwards,
      }));
      setRowData(formattedData);
      setRowCount(awardsData.totalRecords);
    }
  }, [awardsData]);

  useEffect(() => {
    if (attributes?.filterCriteria !== undefined)
      setFilterCriteria(attributes?.filterCriteria);
  }, [attributes?.filterCriteria]);

  const onPageChange = (value) => {
    setPage(value);
    setFilterCriteria((prev) => ({
      ...prev,
      pageNumber: value,
    }));
  };

  const onPageSizeChange = (value) => {
    setPageSize(value);
    setFilterCriteria((prev) => ({
      ...prev,
      pageNumber: 1,
      pageSize: value,
    }));
  };

  const applyFilter = (localFilterValues) => {
    setFilterCriteria((prev) => ({
      ...prev,
      pageNumber: 1,
      awardName: localFilterValues.awardName,
      organization: localFilterValues.organization,
      firstName: localFilterValues.firstName,
      lastName: localFilterValues.lastName,
    }));
  };

  useEffect(() => {
    setAwardsData(filterCriteria);
  }, [filterCriteria, setAwardsData]);

  useEffect(() => {
    if (openSlider) {
      // Disable scroll
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      // Re-enable scroll
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }

    // Clean up the effect when the component unmounts or isOpen changes
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [openSlider]);

  const fullNameRender = (props) => {
    let awardeeInfo = props?.data;
    return (
      <div>
        {props?.value}
        {awardeeInfo.isAAS === "a" && (
          <img
            className="ms-2 award-image"
            title={"American Association of Arts and Sciences"}
            src={AAS}
            alt="AAS"
          />
        )}
        {awardeeInfo.isNAM === "#" && (
          <img
            className="ms-2 award-image"
            title={"National Academy of Medicine"}
            src={NAM}
            alt="NAM"
          />
        )}
        {awardeeInfo.isNAS === "!" && (
          <img
            className="ms-2 award-image"
            title={"National Academy of Sciences"}
            src={NAS}
            alt="NAS"
          />
        )}
        {awardeeInfo.isNAE === "*" && (
          <img
            className="ms-2 award-image"
            title={"National Academy of Engineering"}
            src={NAE}
            alt="NAE"
          />
        )}
        {awardeeInfo.isNAI === "^" && (
          <img
            className="ms-2 award-image"
            title={"National Academy of Inventors"}
            src={NAI}
            alt="NAI"
          />
        )}
      </div>
    );
  };

  const isUTARender = (props) => {
    return props.value === "checked" ? <>Yes</> : <>No</>;
  };

  const clearFilter = () => {
    setLocalFilter({
      awardName: "",
      organization: "",
      firstName: "",
      lastName: "",
    });
    setFilterCriteria({
      pageNumber: 1,
      pageSize: 20,
    });
  };

  const recentAwardeesColumnDefs = [
    {
      headerName: "No of awards",
      field: "noOfAwards",
      style: { maxWidth: "50px", width: "100px" },
      sortable: false,
    },
    {
      headerName: "Full Name",
      field: "fullName",
      style: { maxWidth: "120px", width: "100px" },
      sortable: false,
      cellRenderer: fullNameRender,
    },
    {
      headerName: "IsUTA",
      field: "isUTA",
      style: { maxWidth: "80px", width: "100px" },
      sortable: false,
      cellRenderer: isUTARender,
    },
  ];
  const handleRowclicked = (rowData) => {
    rowData.id && setAwardeeData(rowData?.id);
    setSliderTitle(rowData?.fullName);
    // document.body.style.overflow = "hidden";
    setOpenSlider(true);
  };

  const handleOnCloseClick = () => {
    setOpenSlider(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      {!attributes?.isHomepage && (
        <div className="awardees-filter-container mb-4 p-3">
          <AwardsFilters
            setFilterCriteria={setLocalFilter}
            applyFilter={applyFilter}
            clearFilter={clearFilter}
            isAwardsPage={false}
          />
        </div>
      )}
      <div className="p-3 bg-white">
        <Grid
          columnDefs={recentAwardeesColumnDefs}
          rowData={rowData}
          serverSidePagination
          rowCount={rowCount}
          serverSideSorting
          showPagination
          showFilterIcon
          pageSizeList={[10, 20, 50, 100]}
          pageSize={pageSize}
          page={page}
          masterDetail
          headerContent={"Awardees"}
          showTotalRowCount
          uniqueIdentifierFieldName={"id"}
          sortBy={(columnName, order) => {
            sortOrder(columnName, order);
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          searchFieldName={"Award"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onRowClicked={handleRowclicked}
          isLoading={isLoading}
        />
        <Slider isOpen={openSlider} onClose={handleOnCloseClick}>
          <Awardee
            awardeeData={awardeeData}
            sliderTitle={sliderTitle}
            handleOnCloseClick={handleOnCloseClick}
          />
        </Slider>
      </div>
    </>
  );
};

export default RecentAwardees;
