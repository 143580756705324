import { useLazyGetAwardeeByIdNameQuery } from "../../services/rtkQueryServices/checkApiService";
import "./Awardees.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Awardee = ({ awardeeData, sliderTitle, handleOnCloseClick }) => {
  const [setAwardData, { data: awardData }] = useLazyGetAwardeeByIdNameQuery();
  const [currentAwardData, setCurrentAwardData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (awardData) {
      navigate("/award", {
        state: { awardData: awardData, currentAwardData: currentAwardData },
      });
      if (handleOnCloseClick) handleOnCloseClick();
    }
  }, [awardData, navigate, currentAwardData]);

  const handleRowClicked = (data) => {
    if (data.fk_award && data.Award_Name) {
      setAwardData(data.fk_award);
      const formattedData = {
        awardName: data.Award,
        awardkind: data.Amount === "Unspecified" ? "Recognition" : data.Amount,
        category: data.Category,
        level: data.Level,
        awardNotes: data.Notes,
        percentageWomen: data.PercentageWomen,
        firstYearRecorded: data.firstYearRecorded,
        id: data.pk_award,
        website: data.Website,
      };
      setCurrentAwardData(formattedData);
    }
  };
  return (
    <>
      <div className="font-weight-bold mb-3 award-title-wrapper">
        {"Awards won by " + sliderTitle}
      </div>
      <div>
        {awardeeData?.data?.map((award, index) => (
          <div
            key={index}
            className={
              award.fk_award === 5366
                ? "d-none"
                : "p-2 mb-2 award-wrapper cursor-pointer"
            }
            onClick={() => {
              handleRowClicked(award);
            }}
          >
            {award.Year_of_Award}-{award.Award_Name}
          </div>
        ))}
      </div>
    </>
  );
};
export default Awardee;
