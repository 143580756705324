import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useGetAllcountsQuery } from "../../services/rtkQueryServices/checkApiService";
import { ReactComponent as Awards } from "../../assets/svg/Awards.svg";
import { ReactComponent as Categories } from "../../assets/svg/Categories.svg";
import { ReactComponent as Societies } from "../../assets/svg/Societies.svg";
import "./counts.scss";

const Counts = () => {
  const { data: countData, error, isLoading } = useGetAllcountsQuery();
  const navigate = useNavigate();

  if (isLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6">Error: {error.message}</Typography>;
  }

  const countsData = [
    {
      title: "Awards",
      count: countData?.unique_awards_count,
      renderSvg: <Awards />,
      route: "/awards",
      isShowGo: true,
    },
    {
      title: "Awardees",
      count: countData?.unique_awardees_count,
      renderSvg: <Awards />,
      route: "/awardees",
      isShowGo: true,
    },
    {
      title: "Societies",
      count: countData?.unique_societies_count,
      renderSvg: <Societies />,
      route: "/societies",
      isShowGo: true,
    }

    // {
    //   title: "NRC Highly Prestigious Awards",
    //   count: countData?.nrc_highly_prestigious_count,
    //   renderSvg: <Awards />,
    //   route: "/nrc-highly-prestigious-awards",
    //   isShowGo: false,
    // },
    // {
    //   title: "AAU Highly Prestigious Awards",
    //   count: countData?.aau_highly_prestigious_count,
    //   renderSvg: <Awards />,
    //   route: "/aau-highly-prestigious-awards",
    //   isShowGo: false,
    // },
    // {
    //   title: "GURI Highly Prestigious Awards",
    //   count: countData?.guri_highly_prestigious_count,
    //   renderSvg: <Awards />,
    //   route: "/guri-highly-prestigious-awards",
    //   isShowGo: false,
    // },
    // {
    //   title: "NRC Prestigious Awards",
    //   count: countData?.nrc_prestigious_count,
    //   renderSvg: <Awards />,
    //   route: "/nrc-prestigious-awards",
    //   isShowGo: false,
    // },
  ];

  return (
    <div className="counts-wrapper">
      <h4 className="font-weight-bold" style={{ color: "#000;" }}>
        Overview
      </h4>
      <div className="d-flex flex-wrap bg-white p-3 mb-3">
        {countsData.map((item, index) => (
          <>
            <div
              className="mx-2 counts-content d-flex align-items-center justify-content-between  "
              key={index}
            >
              <div>
                <div className="font-weight-bold">{item.title}</div>
                <div>{item.count}</div>
              </div>
              {item.isShowGo && (
                <div>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => navigate(item.route)}
                    className="mt-2"
                  >
                    Go
                  </Button>
                </div>
              )}
            </div>
            {index !== countsData.length - 1 && <div className="vr my-1"></div>}
          </>
        ))}
      </div>
    </div>
  );
};

export default Counts;
