import React, { useState, useEffect } from "react";
import {
  useLazyGetAwardeeByIdNameQuery,
  useLazyGetAwardsQuery,
} from "../../services/rtkQueryServices/checkApiService";
import Grid from "../Shared/Grid/Grid";
import AwardsFilters from "../AwardsFilters/AwardsFilters";
import { useNavigate } from "react-router-dom";

const Awards = (Props) => {
  const [rowData, setRowData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [setAwardsData, { data: awardsData, isSuccess, isLoading }] =
    useLazyGetAwardsQuery();
  const [setAwardData, { data: awardData }] = useLazyGetAwardeeByIdNameQuery();
  const [currentAwardData, setCurrentAwardData] = useState();
  const [rowcount, setRowCount] = useState(0);
  const [filterCriteria, setFilterCriteria] = useState({
    pageNumber: 1,
    pageSize: 20,
  });
  const [pageSize, setPagesize] = useState(20);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const sortOrder = (columnName, order) => {
    if (order === "asc" || order === "desc") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    }
  };

  useEffect(() => {
    if (Props?.filterCriteria !== undefined)
      setFilterCriteria(Props?.filterCriteria);
  }, [Props?.filterCriteria]);

  useEffect(() => {
    if (isSuccess && awardsData?.data) {
      const formattedData = awardsData.data.map((award) => ({
        awardName: award.Award,
        awardkind:
          award.Amount === "Unspecified" ? "Recognition" : award.Amount,
        category: award.Category,
        level: award.Level,
        awardNotes: award.Notes,
        percentageWomen: award.PercentageWomen,
        firstYearRecorded: award.firstYearRecorded,
        id: award.pk_award,
        website: award.Website,
      }));
      setRowData(formattedData);
      setRowCount(awardsData.totalRecords);
    }
  }, [isSuccess, awardsData]);

  useEffect(() => {
    setAwardsData(filterCriteria);
  }, [filterCriteria, setAwardsData]);

  useEffect(() => {
    if (awardData) {
      navigate("/award", {
        state: { awardData: awardData, currentAwardData: currentAwardData },
      });
    }
  }, [awardData, navigate, currentAwardData]);

  const handleRowClicked = (rowData) => {
    if (rowData.id && rowData.awardName) {
      setAwardData(rowData.id);
      setCurrentAwardData(rowData);
    }
  };

  const onPageChange = (value) => {
    setPage(value);
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };

  const onPageSizeChange = (value) => {
    setPagesize(value);
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };

  const applyFilter = (localFilter) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      awardName: localFilter.awardName,
      organization: localFilter.organization,
      firstName: localFilter.firstName,
      lastName: localFilter.lastName,
    });
  };

  const clearFilter = () => {
    setFilterCriteria({
      pageNumber: 1,
      pageSize: 20,
    });
  };

  const testColumnDefs = [
    {
      headerName: "Award",
      field: "awardName",
      style: { maxWidth: "200px", width: "100px" },
      sortable: false,
    },
    {
      headerName: "Kind",
      field: "awardkind",
      style: { maxWidth: "80px", width: "80px" },
      sortable: false,
    },
    {
      headerName: "Category",
      field: "category",
      style: { maxWidth: "100px", width: "100px" },
      sortable: false,
    },
    {
      headerName: "Level",
      field: "level",
      style: { maxWidth: "100px", width: "100px" },
      sortable: false,
    },
  ];

  return (
    <>
      {!Props?.isHomePage && (
        <div className="bg-white p-3 mb-3">
          <AwardsFilters
            setFilterCriteria={setFilterCriteria}
            applyFilter={applyFilter}
            clearFilter={clearFilter}
            isAwardsPage={true}
          />
        </div>
      )}
      <div className="p-3 bg-white">
        <Grid
          columnDefs={testColumnDefs}
          rowData={rowData}
          serverSidePagination
          rowCount={rowcount}
          serverSideSorting
          showPagination
          showFilterIcon
          pageSizeList={[10, 20, 50, 100]}
          pageSize={pageSize}
          page={page}
          masterDetail
          headerContent={"Awards"}
          showTotalRowCount
          uniqueIdentifierFieldName={"id"}
          sortBy={(columnName, order) => {
            sortOrder(columnName, order);
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          searchFieldName={"Award"}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onRowClicked={handleRowClicked}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Awards;
