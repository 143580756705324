import "./App.scss";
import Loader from "./components/Shared/Widgets/Loader/Loader.js";
import SideBarComponent from "./components/Shared/Widgets/SideBar/sideBar";
import RoutesConfig from "./config/routesConfig.jsx";
import React from "react";
import { useSelector } from "react-redux";

function App() {
  const isLoading = useSelector((state) => state.loading.isLoading);
  return (
    <div className="App">
      {isLoading && <Loader />}
      <SideBarComponent />
      <div className="main-container p-4">
        <RoutesConfig />
      </div>
    </div>
  );
}

export default App;
