import "./Autocomplete.scss";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function ComboBox({
  autoCompleteOptions,
  autoCompleteLabel,
  onSearch,
  onSelect,
  value,
}) {
  const [inputValue, setInputValue] = React.useState(value?.label || "");
  const [selectedValue, setSelectedValue] = React.useState(value || null);

  React.useEffect(() => {
    if (value) {
      setInputValue(value.label);
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <Autocomplete
      disablePortal
      options={autoCompleteOptions}
      sx={{ width: 300 }}
      inputValue={inputValue}
      value={selectedValue}
      noOptionsText={"Select Options"}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onSearch(newInputValue);
      }}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        onSelect(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={autoCompleteLabel} />
      )}
    />
  );
}

export default ComboBox;
