import "./Societies.scss";
import {
  useLazyGetAwardsBySocietyNumberQuery,
  useLazyGetSocietyByIdQuery,
  useLazyGetSocitiesBySearchStringQuery,
  useLazyGetAwardeeByIdNameQuery,
} from "../../services/rtkQueryServices/checkApiService";
import { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import ComboBox from "../Shared/Widgets/Autocomplete/Autocomplete";
import { useNavigate } from "react-router-dom";

const Societies = () => {
  const [setSociety, { data: societyData }] = useLazyGetSocietyByIdQuery();
  const [societyGraphData, setSocietyGraphData] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [triggerFetchSocieties, { data: socitiesData, isSuccess }] =
    useLazyGetSocitiesBySearchStringQuery();
  const [setAwardData, { data: awardData }] = useLazyGetAwardeeByIdNameQuery();
  const [triggerFetchAwardsBySociety, { data: awardsData }] =
    useLazyGetAwardsBySocietyNumberQuery();
  const [awards, setAwards] = useState();
  const [currentAwardData, setCurrentAwardData] = useState();
  const [selectedSociety, setSelectedSociety] = useState({
    id: 1545,
    label: "American Physical Society (APS)",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setSociety(1545);
    triggerFetchAwardsBySociety(1545);
  }, [setSociety]);

  useEffect(() => {
    if (awardsData?.data) {
      const formattedData = awardsData.data.map((award) => ({
        awardName: award.Award,
        awardkind:
          award.Amount === "Unspecified" ? "Recognition" : award.Amount,
        category: award.Category,
        level: award.Level,
        awardNotes: award.Notes,
        percentageWomen: award.PercentageWomen,
        firstYearRecorded: award.firstYearRecorded,
        id: award.pk_award,
        website: award.Website,
      }));
      setAwards(formattedData);
    }
  }, [awardsData]);

  useEffect(() => {
    if (societyData) {
      let dummy = [];
      societyData?.forEach((data) => {
        for (let i = 1981; i < 2024; i++) {
          dummy.push({
            "Men-Gender Neutral": data[`total_men_${i}`],
            "Women-Gender Neutral": data[`total_women_${i}`],
            year: i,
          });
        }
      });
      setSocietyGraphData(dummy);
    }
  }, [societyData]);

  useEffect(() => {
    if (isSuccess && socitiesData.data) {
      setAutoCompleteOptions(socitiesData.data);
    }
  }, [socitiesData]);

  useEffect(() => {
    if (awardData) {
      navigate("/award", {
        state: { awardData: awardData, currentAwardData: currentAwardData },
      });
    }
  }, [awardData, navigate, currentAwardData]);

  function valueFormatter(value) {
    return `${value} people`;
  }

  const fetchSocieties = async (query) => {
    try {
      triggerFetchSocieties(query);
    } catch (error) {
      console.error("Error triggering awards fetch:", error);
    }
  };

  const handleSocietySelect = (value) => {
    setSelectedSociety(value);
    setSociety(value?.id);
    triggerFetchAwardsBySociety(value?.id);
  };

  const handleAwardClick = (awardDetails) => {
    setCurrentAwardData(awardDetails);
    setAwardData(awardDetails?.id);
  };

  const chartSetting = {
    yAxis: [
      {
        label: "Number of People",
      },
    ],
    xAxis: [
      {
        label: "Year",
        scaleType: "band",
        dataKey: "year",
        tickPlacement: "middle",
      },
    ],
    width: 700,
    maxWidth: "100%",
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-6px, 0)",
      },
    },
  };

  return (
    <div className="bg-white p-3 h-100 overflow-auto d-flex societies-wrapper">
      <div className="society-left-wrapper">
        <ComboBox
          autoCompleteOptions={autoCompleteOptions || []}
          autoCompleteLabel={`Society`}
          onSearch={fetchSocieties}
          onSelect={(society) => handleSocietySelect(society)}
          value={selectedSociety}
        />
        {societyGraphData && (
          <>
            <div className="note-text my-4">
              Number of People per year <br />
              Separated by Gender Neutral Awards and Women-only Awards
            </div>
            <div className="society-label">
              Society : {selectedSociety?.label}
            </div>
            <div className="bar-chart-wrapper">
              <BarChart
                dataset={societyGraphData}
                xAxis={[{ scaleType: "band", dataKey: "year" }]}
                series={[
                  {
                    dataKey: "Men-Gender Neutral",
                    label: "Men",
                    stack: true,
                    color: "#2e96ff",
                    valueFormatter,
                  },
                  {
                    dataKey: "Women-Gender Neutral",
                    label: "Women",
                    stack: true,
                    color: "red",
                    valueFormatter,
                  },
                ]}
                {...chartSetting}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <div className="font-weight-bold">Awards</div>
        {awards &&
          awards.map((award) => (
            <div
              className="award-name-wrapper"
              onClick={() => {
                handleAwardClick(award);
              }}
            >
              {award?.awardName}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Societies;
