import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserService } from "../UserService";
import { Mutex } from "async-mutex";
import { startLoading, stopLoading } from "../loadingSlice";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders(headers, api) {
    const token = UserService.accessToken;
    if (token && !headers.has("Authorization")) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result;
  try {
    api.dispatch(startLoading()); // Start loading
    result = await baseQuery(args, api, extraOptions);
    if (
      result.error &&
      result.error.status === 401 &&
      api.endpoint !== "login"
    ) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const refreshResult = await baseQuery(
            {
              url: ``,
              // url: `${API_CONSTANTS.BASE_RIBBON_AUTHENTICATION_BASE_URL}${API_CONSTANTS.REFRESH_TOKEN}`,
              body: JSON.stringify({
                refreshToken: UserService.refreshToken,
                token: UserService.accessToken,
              }),
              method: "POST",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            },
            api,
            extraOptions
          );
          if (refreshResult.data) {
            UserService.logIn(refreshResult.data.data);
            result = await baseQuery(args, api, extraOptions);
          } else {
            UserService.redirectToSessionExpiry();
          }
        } catch (e) {
          UserService.redirectToSessionExpiry();
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  } finally {
    api.dispatch(stopLoading()); // Stop loading
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export default baseApi;
