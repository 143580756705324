import { Route, Routes } from "react-router-dom";
import HomeComponent from "../components/Home/home";
import Awards from "../components/Awards/awards.jsx";
import RecentAwardees from "../components/RecentAwardees/recentAwardees.js";
import Award from "../components/Award/Award.jsx";
import UTAFacultyAwards from "../components/UTAFacultyAwards/UTAFacultyAwards.jsx";
import NetworkGraph from "../components/AwardsAnalyticsStatistics/NetworkGraph.js";
import NetworkGraphAwardees from "../components/NetworkGraphAwardees/Awardees.jsx";
import Societies from "../components/Societies/Societies.jsx";
const RoutesConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeComponent />} />
      <Route path="/awards" element={<Awards />} />
      <Route path="/awardees" element={<RecentAwardees />} />
      <Route path="/award" element={<Award />} />
      <Route path="/uta_faculty_awards" element={<UTAFacultyAwards />} />
      <Route path="/network_graph" element={<NetworkGraph />} />
      <Route path="/network_awardees" element={<NetworkGraphAwardees />} />
      <Route path="/societies" element={<Societies />} />
    </Routes>
  );
};

export default RoutesConfig;
