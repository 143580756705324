export const API_CONSTANTS = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GET_SOCIETY_BY_ID: "/society",
  GET_RECENT_AWARDEES: "/recent-awardees",
  GET_ALL_COUNTS: "/counts",
  GET_ALL_AWARDS: "/awards",
  GET_AWARDEE_BY_ID: "/awardee",
  GET_AWARDEE_BY_ID_NAME: "/awardeeByAwardIdawardName",
  GET_AWARD_GRAPH_ID: "/awardGraph",
  GET_AWARD_BY_ID: "/award",
  GET_UTA_FACULTY_MASTER_DATA: "/master_uta_faculty_awards",
  GET_UTA_FACULTY_BY_ID: "/utaFaculty",
  GET_AWARDS_BY_SOCIETY_NUMBER: "/awardsBySocietyNumber",
  GET_AWARDS_BY_SEARCH_STRING: "/awardSearch",
  GET_SOCIETIES_BY_SEARCH_STRING: "/societySearch",
  GET_NETWORK_GRAPH_COUNTS: "/awards-combination",
  GET_NETWORK_GRAPH_AWARDEES: "/common-awardees",
};
