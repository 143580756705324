import React, { useEffect, useState, useRef } from "react";
import "./NetworkGraph.scss";
import ComboBox from "../Shared/Widgets/Autocomplete/Autocomplete";
import {
  useLazyGetAwardsBySearchStringQuery,
  useLazyGetNetworkGraphCountsQuery,
  useLazyGetNetworkGraphAwardeesQuery,
} from "../../services/rtkQueryServices/checkApiService";
import { Button } from "@mui/material";
import { Network } from "vis-network/standalone/esm/vis-network";
import { useNavigate } from "react-router-dom";
import Modal from "../Shared/Widgets/Modal/Modal";
import Slider from "../Shared/Widgets/Slider/Slider";
import NetworkGraphAwardees from "../NetworkGraphAwardees/Awardees";

const NetworkGraph = () => {
  const [allAwards, setAllAwards] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const [selectedAwards, setSelectedAwards] = useState([
    {
      id: 5993,
      label: "Member National Academy of Engineering",
    },
    {
      id: 5994,
      label: "Fellow National Academy of Inventors",
    },
    {
      id: 419,
      label: "The National Medal of Technology and Innovation",
    },
  ]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([[], [], []]);
  const [triggerFetchAwards, { data: awardsData, isSuccess }] =
    useLazyGetAwardsBySearchStringQuery();
  const [triggerFetchGraphData, { data: networkgraphCounts }] =
    useLazyGetNetworkGraphCountsQuery();
  const [triggerFetchAwardees, { data: awardeesData }] =
    useLazyGetNetworkGraphAwardeesQuery();
  const tempNodes = [];
  const networkContainerRef = useRef(null);
  const networkRef = useRef(null);

  const [showLegend, setShowLegend] = useState(false);

  const nodeColors = ["#FF6347", "#4682B4", "#32CD32", "#FFD700"];
  const fetchAwards = async (query) => {
    try {
      triggerFetchAwards(query);
    } catch (error) {
      console.error("Error triggering awards fetch:", error);
    }
  };

  useEffect(() => {
    if (isSuccess && awardsData?.data) {
      setAllAwards(awardsData.data);
    }
  }, [awardsData, isSuccess]);

  useEffect(() => {
    if (awardeesData && awardeesData.length) {
      if (awardeesData.length > 50) setIsModalOpen(true);
      else setOpenSlider(true);
    }
  }, [awardeesData, isClicked]);

  const filterAwards = (selectedAwards, currentAward) =>
    allAwards.filter(
      (award) =>
        !selectedAwards.some(
          (selected) => selected && selected.id === award.id
        ) ||
        (currentAward && currentAward.id === award.id)
    );

  useEffect(() => {
    const filteredOptions = selectedAwards.map((selectedAward, index) =>
      filterAwards(
        selectedAwards.filter((_, i) => i !== index), // Exclude current selection
        selectedAward
      )
    );
    setAutoCompleteOptions(filteredOptions);
  }, [selectedAwards, allAwards]);

  const handleAwardSelect = (award, index) => {
    const updatedAwards = [...selectedAwards];
    updatedAwards[index] = award;
    setSelectedAwards(updatedAwards);
  };

  const handleBuildClick = () => {
    const validAwards = selectedAwards.filter(Boolean);
    const awardIds = validAwards.map((award) => award.id);

    if (awardIds.length >= 2) {
      triggerFetchGraphData(awardIds);
    }
  };

  const handleGraphClick = (data) => {
    const ids = data?.map((item) => item.id);
    setCurrentData(data);
    setIsClicked(!isClicked);
    triggerFetchAwardees(ids);
  };
  useEffect(() => {
    const validAwards = selectedAwards.filter(Boolean);

    if (networkgraphCounts && validAwards.length >= 2) {
      const nodes = validAwards.map((award, index) => ({
        id: award.id,
        label: award.name,
        color: nodeColors[index],
      }));

      nodes.push({
        id: "central_node",
        label: `Total: ${networkgraphCounts.total_common_across_all}`,
        color: { background: nodeColors[3], border: "black" },
        shape: "box",
      });

      const edges = [];

      validAwards.forEach((award1, i) => {
        validAwards.slice(i + 1).forEach((award2) => {
          edges.push({
            from: award1.id,
            to: award2.id,
            label: `${
              networkgraphCounts.common_between_awards[
                `common_award_${award1.id}_and_award_${award2.id}`
              ]
            }`,
            data: [award1, award2],
          });
        });
      });

      validAwards.forEach((award) => {
        tempNodes.push(award);
        edges.push({
          from: award.id,
          to: "central_node",
          dashes: true,
          data: tempNodes,
        });
      });

      const data = { nodes, edges };

      const options = {
        edges: {
          font: {
            align: "middle",
          },
        },
        nodes: {
          shape: "dot",
          size: 30,
        },
        layout: {
          improvedLayout: true,
        },
        physics: {
          stabilization: true,
        },
      };

      if (networkContainerRef.current) {
        networkRef.current = new Network(
          networkContainerRef.current,
          data,
          options
        );
        setShowLegend(true);
        networkRef.current.on("click", function (params) {
          if (params.edges.length > 0) {
            const edgeId = params.edges[0];
            const edge = edges.find((edge) => edge.id === edgeId);
            if (
              edge &&
              edge.data &&
              edge.label != "0" &&
              edge.label != "undefined"
            ) {
              handleGraphClick(edge.data);
            }
          }
        });
      }
    }
  }, [networkgraphCounts, selectedAwards]);

  useEffect(() => {
    handleBuildClick();
  }, []);

  return (
    <div className="network-graph-container w-100 bg-white p-4">
      <div>
        <div className="note-wrapper mb-3">
          Note: Only awards with 50 or more awardees are displayed in the
          dropdown list.
        </div>
        <div className="d-flex justify-content-between awards-dropdown-wrapper">
          {selectedAwards.map((selectedAward, index) => (
            <div key={index}>
              <ComboBox
                autoCompleteOptions={autoCompleteOptions[index]}
                autoCompleteLabel={`Award ${index + 1}`}
                onSearch={fetchAwards}
                onSelect={(award) => handleAwardSelect(award, index)}
                value={selectedAward}
              />
            </div>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleBuildClick}
            disabled={selectedAwards.filter(Boolean).length < 2}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex legend-netwrok-graph-wrapper">
          {showLegend && (
            <div className="network-graph-legend" style={{ marginTop: "20px" }}>
              <div
                style={{ gap: "20px" }}
                className="justify-content-center flex-column d-flex h-100"
              >
                {selectedAwards.map(
                  (award, index) =>
                    award && (
                      <div
                        key={award.id}
                        className="legend-wrapper d-flex align-items-center"
                      >
                        <div
                          className="color-legend"
                          style={{
                            backgroundColor: nodeColors[index],
                          }}
                        ></div>
                        {networkgraphCounts?.individual_award_counts[award.id]}{" "}
                        - {award.label}
                      </div>
                    )
                )}
                <div className="legend-wrapper d-flex align-items-center">
                  <div
                    className="color-legend"
                    style={{
                      backgroundColor: nodeColors[3],
                    }}
                  ></div>
                  Total Common Across All
                </div>
              </div>
            </div>
          )}
          <div
            ref={networkContainerRef}
            className="network-graph flex-grow-1"
            style={{ height: "450px", minHeight: "450px" }}
          ></div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={"Awardees"}
      >
        <NetworkGraphAwardees
          currentData={currentData}
          awardeesData={awardeesData}
        />
      </Modal>
      <Slider
        isOpen={openSlider}
        onClose={() => {
          setOpenSlider(false);
        }}
      >
        <NetworkGraphAwardees
          currentData={currentData}
          awardeesData={awardeesData}
        />
      </Slider>
    </div>
  );
};

export default NetworkGraph;
